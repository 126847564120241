import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa6";
import notFound from "../../../assets/images/no-pairs2.gif"
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../../../core/services/v1/request";
import Config from "../../../core/config/index"
import { toast } from "../../../core/lib/toastAlert";
import $ from "jquery"
export default function Pairtable(props) {

  const { fliteredPairList, showPairName, decimalValue, ToggleClass, selectedCurrency, getFavPairList } = props;
  const navigate = useNavigate();
  const unFavourite = async (symbol) => {
    try {
      let userFavPairs = JSON.parse(localStorage.getItem("userFavPairs"));
      if (userFavPairs.includes(symbol)) {
        const index = userFavPairs.indexOf(symbol);
        if (index > -1) {
          userFavPairs.splice(index, 1);
        }
        localStorage.setItem("userFavPairs", JSON.stringify(userFavPairs));
        toast({ type: "success", message: "Pair Remove from you'r favourite list" });
      } else {
        toast({ type: "info", message: "This is not you'r fav pair" });
      };
    } catch (error) {
      toast({ type: "error", message: "Something Went Wrong!" })
    };
  };

  return (
    <div>
      <div className=" top-new-spot-ser-table">
        <table className="table mt-2">
          <thead >
            <tr >
              <th style={{ borderRadius: "0px" }}>Pair</th>
              <th>Price</th>
              <th>24H Change</th>
            </tr>
          </thead>
          <tbody>
            {
              fliteredPairList && fliteredPairList.length > 0 ? (
                fliteredPairList.map((market, index) => {
                  console.log("market",market)
                  const userFavPair = JSON.parse(localStorage.getItem("userFavPairs")) || [];
                  const userFav = userFavPair.some((pair) => pair == market.pair);

                  const marketPair = market.pair;
                  const basePair = marketPair.split("_")[0];
                  const quotePair = marketPair.split("_")[1];
                  return (
                    <tr className="cursor-poniter" key={index} onClick={() => {
                      navigate("/spot/" + market.pair);
                      ToggleClass();
                    }}>
                      <td>
                        <div className="d-flex align-items-end gap-1">
                          {userFav && selectedCurrency == "Fav" && <button className="spot-ser-btn"><FaStar style={{ color: "#3853FF" }} onClick={() => unFavourite(market.pair)} /></button>}
                          <p className="spot-ser-text-1" >{basePair}</p>
                          <span className="spot-ser-text-2">/{quotePair}</span>
                        </div>
                      </td>
                      <td>
                        <span className="spot-ser-text-1"> {
                          decimalValue(
                            market.marketPrice,
                            market.priceDecimal,
                            "removeZero"
                          )
                        }</span>
                      </td>
                      <td>
                        <span
                          className={
                            market.changePer > 0
                              ? "color-green spot-ser-text-1"
                              : market.changePer === 0
                                ? "text-light spot-ser-text-1"
                                : "color-darkpink spot-ser-text-1"
                          }
                        >
                          {(market.changePer > 0
                            ? "+"
                            : "") +
                            decimalValue(
                              market.changePer,
                              market.totalDecimal
                            )}%
                        </span>
                      </td>
                    </tr>
                  )
                })) : (
                <tr>
                  <td className="text-center" colSpan={3}>
                    <div>
                      <img src={notFound} alt="pair-notfound" style={{ width: 40, height: 40 }} />
                      <p className="mb-0">No Pairs Found!</p>
                    </div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}