import React, { useEffect, useState, Suspense, useMemo } from 'react';
import axios from "axios";
import Config from "../../../core/config/index";
import Chart from "./Chart"
const TVChartContainer = React.lazy(() => import("./TVChartContainer"));

export const isBinancePair = async (pair) => {
  try {
    let marketsList = [];
    const response = await axios.get(Config.V1_API_URL + "trade/getBinance/market");
    if (response && response.data && response.data.data) {
      const symbols = response.data.data;
      marketsList = symbols[0].pairs;
      const existPair = marketsList.indexOf(pair) >= 0;
      return existPair;
    } else {
      return false;
    };
  } catch (error) {
    return false;
  };
};

function TradingView(props) {
  const { pairName } = props;
  const [pair, setPair] = useState("BTCUSDT");
  const [isExist, setIsExist] = useState(true)

  useEffect(() => {
    const propSybl = props.symbol;
    const symbol = propSybl.split("/").join("");
    setPair(symbol);
  }, [props, pairName]);

  useEffect(() => {
    const getRes = async () => {
      try {
        const existPair = await isBinancePair(pair);
        setIsExist(existPair)
      } catch (err) {
        console.log("err", err);
        setIsExist(false);
      }
    };
    getRes();
  }, [pair, pairName]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isExist ? (
        <Chart pairName={pairName} pair={pair} theme={props.theme} />
      ) : (
        <TVChartContainer {...props} />
      )}
    </Suspense>
  );
};
export default TradingView;