import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from "chart.js";
import Config from "../core/config";
import { useContextData } from "../core/context/index";
import { makeRequest } from "../core/services/v1/request";
import { getCookie, setCookie } from "../core/helper/cookie";
import DatatableWallet from './DatatableWallet';
import { toast } from "../core/lib/toastAlert";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend
);
export default function Walletpage() {
  const navigate = useNavigate();
  const [walletData, setWalletData] = useState([]);
  const [estimatedValue, setEstimatedValue] = useState({});
  const [balShow, setBalShow] = useState(
    getCookie("userBalShow") ? getCookie("userBalShow") : false
  );
  useEffect(() => {
    getWalletCurrency();
    // depositETHTRX();
  }, []);
  const { myProfile, setUserProfile } = useContextData();
  // async function depositETHTRX() {
  //   try {
  //     const params = {
  //       url: `${Config.V1_API_URL}wallet/depositETHTRX`,
  //       method: "GET",
  //     };
  //     const response = await makeRequest(params);
  //     getWalletCurrency();
  //   } catch (err) { }
  // }

  async function getWalletCurrency() {
    const params = {
      url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
      method: "GET",
    };
    const response = await makeRequest(params);
    if (response) {
      const {
        data = [],
        estimateINR = 0,
        estimateUSD = 0,
      } = response;
      setWalletData(data);
      setEstimatedValue({
        estimateINR,
        estimateUSD,
      });
    }
  }

  const balShowHideCall = () => {
    if (balShow) {
      setCookie("userBalShow", false);
      setBalShow(false);
    } else {
      setCookie("userBalShow", true);
      setBalShow(true);
    }
  };
  // const toNavigateFirstCurrency = (type = "") => {
  //   if (
  //     walletData &&
  //     walletData.length > 0 &&
  //     (type === "deposit" || type === "withdraw")
  //   ) {
  //     let firstData = walletData.filter((e) => e.curnType == "Crypto");
  //     if (firstData && firstData.length > 0) {
  //       navigate("/" + type + "/crypto/" + firstData[0].currencySymbol);
  //     }
  //   }
  // };
  const toNavigateFirstCurrency = (type = "") => {
    if(walletData.length > 0) {
      if (walletData && walletData.length > 0 && (type === "withdraw") && (myProfile.kycstatus == 1 && myProfile.tfaStatus == 1)) {
        let firstData = walletData.filter((e) => e.curnType == "Crypto");
        if (firstData && firstData.length > 0) {
          navigate("/" + type + "/crypto/" + firstData[0].currencySymbol);
        }
      } else if(walletData && walletData.length > 0 && (type === "deposit")) {
        let firstData = walletData.filter((e) => e.curnType == "Crypto");
        navigate("/" + type + "/crypto/" + firstData[0].currencySymbol);
      } else if (myProfile.kycstatus != 1){
        navigate("/my/identification");
        toast({
          type: "error",
          message: "Please complete KYC to continue withdraw process",
        });
      } else if(myProfile.tfaStatus != 1) {
        navigate("/google-authenticator");
        toast({
          type: "error",
          message: "Please complete 2FA process",
        });
        return
      } else {

      }
    }
  };

  return (
    <div>
      {/* tab one start  */}
      <div className="container-fluid px-0">
        <div className="row ">
          <div className="col-lg-12 mt-5">
            <div className="conatiner-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="password-text-55">
                    Estimated Balance{" "}
                    {balShow ? (
                      <AiFillEye onClick={() => balShowHideCall()} className="cursor-pointer" />
                    ) : (
                      <AiFillEyeInvisible onClick={() => balShowHideCall()} className="cursor-pointer" />
                    )}
                  </h4>
                </div>
                <div className="col-lg-6 ">
                  {/* onClick={() => navigate("/wallet")} */}
                  {/* <button className="unset btnfont mb-2 password-text-33 fw-bold">Wallet</button> */}
                  <button
                    className="unset-unselected btnfont mb-2 password-text-33 fw-bold"
                    onClick={() => toNavigateFirstCurrency("deposit")}
                  >
                    Deposit
                  </button>
                  <button
                    className="unset-unselected btnfont mb-2 password-text-33 fw-bold"
                    onClick={() => toNavigateFirstCurrency("withdraw")}
                  >
                    Withdraw
                  </button>
                </div>

                <div>
                  {balShow ? (
                    <p>
                      <span>
                        <b className="border-dot password-text-44">
                        ${estimatedValue.estimateUSD
                            ? estimatedValue.estimateUSD.toFixed(2)
                            : 0}{" "}
                        </b>
                      </span>
                      <span className="textCol">
                        {" "}
                        <b>
                          ≈ ₹
                          {estimatedValue.estimateINR
                            ? estimatedValue.estimateINR.toFixed(2)
                            : 0}
                        </b>
                      </span>
                    </p>
                  ) : (
                    <p>***Balance hidden***</p>
                  )}
                </div>
                <div className="col-lg-12">
                  <div className="col-lg-12 mt-4">
                    <DatatableWallet
                      balShow={balShow}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* tab one end  */}
    </div>
  );
}
