import React, { useEffect, useState } from "react";
import axios from "axios";
import "../assets/style.css";
import { GoChevronLeft } from "react-icons/go";
import { HiArrowNarrowRight } from "react-icons/hi";
import { RiArrowDownSFill, RiFileCopyFill } from "react-icons/ri";
// import {
//   BsLink45Deg,
//   BsFillArrowRightCircleFill,
//   BsInfoCircleFill,
// } from "react-icons/bs";
import {
  BrowserRouter as Router,
  useParams,
  useNavigate,
} from "react-router-dom";
import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import noreplay from "../assets/images/deposit/no-re.png";
import { Form, FormGroup, Label, Input } from "reactstrap";
// import ReactTooltip from "react-tooltip";
import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";
import { useContextData } from "../core/context/index";
import { toast } from "../core/lib/toastAlert";
import { dateFormat } from "../core/helper/date-format";
import { shortAdrress } from "../core/helper/short-address";
import { copyText } from "../core/helper/common";
import DataTable from "react-data-table-component";
import * as yup from "yup";
import { useFormik } from "formik";
import gamecoin from "../assets/images/coin-game.png";
import notFound from "../assets/images/file.png";

const validationSchema = yup.object({});
export default function Withdraw(props) {
  let query = useParams();
  let currencyId = query && query.currencyId ? query.currencyId : "";
  const navigate = useNavigate();
  const { siteSettings, myProfile, ipAddress } = useContextData();

  const [defaultCurrencyList, setdefaultCurrencyList] = useState({
    currencySymbol: "",
    image: "",
    basecoin: "",
  });
  const [userbalance, setUserBalance] = useState(0);
  const [fees, setFeevalue] = useState(0);
  const [feeType, setFeeType] = useState("");
  const [minwithamt, setminwithamt] = useState(0);
  const [currencyList, setCurrencyList] = useState({});
  const [particularcurrency, set_particularCurrency] = useState([]);
  const [networkName, setNetworkName] = useState("");
  const [withdrawshow, setWithdrawshow] = useState(0);
  const [withdrawcryptoList, setWithdrawCryptoList] = useState([]);
  const [networkList, setnetworkList] = useState([]);
  const [networkId, setnetworkId] = useState("");
  const [currencies, setCurrencies] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [passData, setpassData] = useState({});
  const [inrCurrencyList, setinrCurrencyList] = useState({});
  const [searchVal, setSearchVal] = useState([]);
  const [withdrawLevDet, setWithdrawLevDet] = useState({});
  const [levelBasedLimit, setLevelBasedLimit] = useState({});
  const [fillteredData, setFillteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [timer_resendOtp, setTimer_resendOtp] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalorders, settotalorders] = useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [cuurrencySymbol, setCurrencySymbol] = useState(
    query.currencyId ? query.currencyId : "1INCh"
  );

  console.log("currencies", currencies)
  useEffect(() => {
    localStorage.removeItem("networkbase");
    localStorage.removeItem("networkId");
    localStorage.removeItem("currencyImage");
    initCall();
  }, []);

  useEffect(() => {
    timer_resendOtp > 0 &&
      setTimeout(() => setTimer_resendOtp(timer_resendOtp - 1), 1000);
  }, [timer_resendOtp]);

  useEffect(() => {
    if (myProfile) {
      if (myProfile.kycstatus != 1) {
        navigate("/my/identification");
        toast({
          type: "error",
          message: "Please complete KYC to continue withdraw process",
        });
        return;
      }
      if (myProfile.email == "") {
        toast({ type: "error", message: "Please enable email verification" });
        navigate("/my/email-authenticator");
      } else if (myProfile.tfaenablekey == "") {
        toast({
          type: "error",
          message: "Please completed 2FA to process withdraw.",
        });
        navigate("/google-authenticator");
      } else if (myProfile.email) {
        getWalletCurrency_func();
        getinrCurrency();
      }
    }
  }, [myProfile]);

  useEffect(() => {
    getparticularHistory(
      defaultCurrencyList && defaultCurrencyList.currencySymbol
    );
  }, [currentPage, rowsPerPage]);

  async function initCall() {
    try {
      const params = {
        url: `${Config.V2_API_URL}transaction/withdrawLevelDetail`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        setWithdrawLevDet(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getWalletCurrency_func() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        let currencyArray = [];
        let currencySymbol = "";
        let Network = "";
        let defaultNetwork = "";
        let findSymbol = "";
        let image = "";
        let NetworkId = "";
        let basecoin = "";
        const isWithdrawEnable = response.data.filter(
          (data) => data.currencySymbol == currencyId
        );
        if (isWithdrawEnable[0].withdrawEnable == 0) {
          const dataasss = response.data.filter((data) => {
            if (data.withdrawEnable == 1) {
              return data;
            };
          });
          window.location.href = `/withdraw/crypto/${dataasss[0].currencySymbol}`
          findSymbol = dataasss[0].currencySymbol;
        };
        response.data.map((item) => {
          console.log("currecnySymbol", item);
          if (currencyId != "") {
            let findOne = response.data.find(
              (element) => element.currencySymbol == currencyId
            );
            if (findOne.curnType == "Fiat") {
              findSymbol = response.data[0]._id;
            } else {
              findSymbol = currencyId;
            }
          } else {
            if (response.data[0].currencySymbol == "PI") {
              navigate("/withdraw/Pi");
            } else {
              navigate(
                `/withdraw/${response.data[0].curnType.toLowerCase()}/${response.data[0].currencySymbol
                }`
              );
            }
            window.location.reload();
          }
          if (item.currencySymbol == findSymbol) {
            setnetworkId(item.currencyId);
            NetworkId = item.currencyId;
            currencySymbol = item.currencySymbol;
            Network = item.basecoin;
            defaultNetwork = item._id;
            basecoin =
              item.basecoin == "Coin"
                ? item.currencySymbol == "BNB"
                  ? "BEP20"
                  : item.currencySymbol == "ETH"
                    ? "ERC20"
                    : item.currencySymbol == "TRX"
                      ? "TRC20"
                      : item.currencySymbol == "DCX"
                        ? "DCX20"
                        : item.currencySymbol == "AVAX"
                          ? "AVAX20"
                          : item.currencySymbol == "ARB"
                            ? "ARB20"
                            : item.currencySymbol == "CRO"
                              ? "CRO20"
                              : item.currencySymbol == "FTM"
                                ? "FTM20"
                                : item.currencySymbol == "OP"
                                  ? "OP20"
                                  : item.currencySymbol == "POL"
                                    ? "POL20"
                                    : ""
                : item.basecoin;
            image = item.image;
            item.ids.map((item) => {
              if (item.withdrawEnable == 0) {
              }
              if (item.withdrawEnable == 1) {
              }
            });
            setnetworkList(item.ids);
          }
          if (item.withdrawEnable == 1) {
            currencyArray.push({
              value: item._id,
              label: item.currencySymbol,
              image: item.image,
              currencyName: item.currencyName,
              balance: item.balance,
            });
          }
        });
        setCurrencyList(currencyArray);
        let local = localStorage.getItem("networkbase");
        setdefaultCurrencyList({
          currencySymbol: currencySymbol,
          image: image,
          basecoin: local == undefined ? basecoin : local,
        });
        console.log("NetworkId", NetworkId);
        setNetworkName(local == undefined ? Network : local);
        getparticularHistory(currencySymbol);
        getParticularCurrency(defaultNetwork);
        getBalance(NetworkId);
        getFee(defaultNetwork);
      }
    } catch (err) { }
  }

  async function getParticularCurrency(defaultNetwork) {
    try {
      const value = { CurrencyID: defaultNetwork };
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        body: value,
      };
      const response = await makeRequest(params);
      if (response.status) {
        setCurrencies(response.data);
        set_particularCurrency(response.data);
        let local = localStorage.getItem("networkbase");
        setNetworkName(local == undefined ? response.data.basecoin : local);
        setdefaultCurrencyList({
          currencySymbol: response.data.currencySymbol,
          image: response.data.image,
          basecoin: local == undefined ? response.data.basecoin : local,
        });
      }
    } catch (err) { }
  }

  async function getparticularHistory(Currency) {
    const value = {
      type: "Withdraw",
      currencyType: "Crypto",
      currencySymbol: Currency,
      limit: rowsPerPage,
      offset: rowsPerPage * (currentPage - 1),
    };
    const params = {
      url: `${Config.V1_API_URL}wallet/getHistory`,
      method: "POST",
      body: value,
    };
    const result = await makeRequest(params);
    if (result.status) {
      const array = [];
      result.data.map((item) => {
        if (Currency == item.currencyId.currencySymbol) {
          array.push({
            address: item.address,
            currencySymbol: item.currencyId.currencySymbol,
            image: item.currencyId.image,
            amount: item.amount,
            fees: item.fees,
            txnId: item.txnId,
            status: item.status,
            createdDate: item.createdDate,
          });
        }
      });
      setWithdrawCryptoList(array);
      settotalorders(result.total);
    }
  }

  async function getFee(defaultNetwork) {
    const reqData = { CurrencyID: defaultNetwork };
    const params = {
      url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
      method: "POST",
      body: reqData,
    };
    const res = await makeRequest(params);
    if (res.status) {
      setCurrencies(res.data);
      if (
        typeof res.data.withdrawLevel == "object" &&
        typeof res.data.withdrawLevel["level" + myProfile?.level] == "object"
      ) {
        const data = res.data.withdrawLevel["level" + myProfile?.level];
        setFeevalue(data.fees);
        setFeeType(data.feetype);
        setminwithamt(data.minwithamt);
      }
    }
  }
  async function getBalance(currencyId) {
    if (currencyId != "") {
      const value = { currencyId: currencyId };
      const params = {
        url: `${Config.V1_API_URL}wallet/getCurrencyBalance`,
        method: "POST",
        body: value,
      };
      const result = await makeRequest(params);
      if (result.status) {
        var amount = result.data.amount;
        console.log("amount", amount);
        setUserBalance(amount);
      }
    }
  }
  async function handleCurrencyChange(currencySymbol) {
    window.history.pushState(
      {},
      "",
      window.origin + "/withdraw/crypto/" + currencySymbol
    );
    getparticularHistory(
      defaultCurrencyList && defaultCurrencyList.currencySymbol
    );
    localStorage.removeItem("networkbase");
    localStorage.removeItem("networkId");
    localStorage.removeItem("currencyImage");
    setNetworkName("");
    setCurrencySymbol(currencySymbol);
    getSelectedCurrency(currencySymbol);
  }
  async function getSelectedCurrency(findcurrencySymbol) {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        let currencyArray = [];
        let currencySymbol = "";
        let Network = "";
        let NetworkId = networkId;
        let defaultNetwork = "";
        let image = "";
        let basecoin = "";
        response.data.map((item) => {
          if (item.currencySymbol == findcurrencySymbol) {
            setnetworkId(item.currencyId);
            NetworkId = item.currencyId;
            currencyId = item._id;
            currencySymbol = item.currencySymbol;
            basecoin =
              item.basecoin == "Coin"
                ? item.currencySymbol == "BNB"
                  ? "BEP20"
                  : item.currencySymbol == "ETH"
                    ? "ERC20"
                    : item.currencySymbol == "TRX"
                      ? "TRC20"
                      : item.currencySymbol == "DCX"
                        ? "DCX20"
                        : item.currencySymbol == "AVAX"
                          ? "AVAX20"
                          : item.currencySymbol == "ARB"
                            ? "ARB20"
                            : item.currencySymbol == "CRO"
                              ? "CRO20"
                              : item.currencySymbol == "FTM"
                                ? "FTM20"
                                : item.currencySymbol == "OP"
                                  ? "OP20"
                                  : item.currencySymbol == "POL"
                                    ? "POL20"
                                    : item.currencySymbol == "BTC"
                                      ? "BTC"
                                      : item.currencySymbol == "PI"
                                        ? "PI"
                                        : ""
                : item.basecoin;
            image = item.image;
            item.ids.map((item) => {
              Network = item.basecoin;
              defaultNetwork = item._id;
            });
            setnetworkList(item.ids);
          }
          currencyArray.push({
            value: item._id,
            label: item.currencySymbol,
            image: item.image,
            currencyName: item.currencyName,
            balance: item.balance,
          });
        });
        let local = localStorage.getItem("networkbase");
        setNetworkName(local == undefined ? Network : local);
        setdefaultCurrencyList({
          currencySymbol: currencySymbol,
          image: image,
          basecoin: local == undefined ? Network : local,
        });
        getparticularHistory(currencySymbol);
        getBalance(NetworkId);
        getFee(defaultNetwork);
        getParticularCurrency(currencyId);
      }
    } catch (err) { }
  }

  async function handlenetworkChange(networkId, basecoin) {
    setNetworkName(basecoin);
    setnetworkId(networkId);
    getselectedNetwork(networkId);
    getParticularCurrency(networkId);
    getFee(networkId);
    localStorage.setItem("networkId", networkId);
    localStorage.setItem("networkbase", basecoin);
  }
  async function getselectedNetwork(networkId) {
    try {
      const networksId = localStorage.getItem("networkId");
      const value = {
        CurrencyID: networksId == undefined ? networkId : networksId,
      };
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        body: value,
      };
      const response = await makeRequest(params);
      if (response.status) {
        let local = localStorage.getItem("networkbase");
        localStorage.setItem("currencyImage", response.data.image);
        setNetworkName(local == undefined ? response.data.basecoin : local);
        setdefaultCurrencyList({
          currencySymbol: response.data.currencySymbol,
          image: response.data.image,
          basecoin: local == undefined ? response.data.basecoin : local,
        });
        setCurrencies(response.data);
      }
    } catch (err) { }
  }
  async function getinrCurrency() {
    try {
      const value = { currencySymbol: "INR" };
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        body: value,
      };
      const response = await makeRequest(params);
      if (response.status) {
        setinrCurrencyList(response.data);
      }
    } catch (err) { }
  }

  const handleChange = (event) => {
    const { name = "", value, checked } = event.target;
    switch (name) {
      case "address":
      case "amount":
        passData[name] = value;
        setpassData(passData);
        break;
      default:
        break;
    }
  };
  const submitWithdraw = async (values = {}, target = "submit") => {
    let type = "error";
    if (cuurrencySymbol == "PI" && values.address.length < 56) {
      return formik.setErrors({ address: "Invalid Address" });
    }
    if (values.amount <= userbalance) {
      let data = {
        currencyId: currencies && currencies._id,
        amount: values.amount,
        address: values.address,
        tag: "",
      };

      if (
        currencies &&
        currencies.currencySymbol == "XRP" &&
        currencies.basecoin == "Coin"
      ) {
        data.tag = values.tag;
        if (values.tag == "") {
          formik.errors.tag = "Tag is required";
          return false;
        }
      }

      if (withdrawshow != 0 && target !== "resendOTP") {
        data.withdrawOTP = values.withdrawOTP ? values.withdrawOTP : "-";
        data.tfaCode = values.tfaCode;
      }
      data.ipDetails = ipAddress;
      if (withdrawshow != 0 && data.withdrawOTP == "-") {
        return formik.setErrors({ withdrawOTP: "Please enter OTP" });
      }
      if (withdrawshow != 0 && data.tfaCode == "") {
        return formik.setErrors({ tfaCode: "Please enter 2FA code" });
      }
      const params = {
        url: `${Config.V1_API_URL}wallet/submitWithdraw`,
        method: "POST",
        body: data,
      };
      setisLoading(true);
      const response = await makeRequest(params);
      setisLoading(false);
      if (response.status) {
        type = "success";
        setWithdrawshow(response.type);
        if (response.type == 3) {
          formik.resetForm();
          setWithdrawshow(0);
          getparticularHistory(
            defaultCurrencyList && defaultCurrencyList.currencySymbol
          );
          // window.location.href = `/withdraw/crypto/${defaultCurrencyList.currencySymbol}`
          // getWalletCurrency_func();
          getBalance(networkId);
        }
        if (withdrawshow == 0 || target === "resendOTP") {
          setTimer_resendOtp(Config.timer.resendOtp);
        }

      } else {
        const errmsg = response.message.split(" ");
        if (response.type == 4 && (errmsg[2] == "TFA" || errmsg[2] == "2FA")) {
          navigate("/my/google-authenticator");
        } else if (response.type == 4 && errmsg[2] == "KYC") {
          navigate("/my/identification");
        } else if (response.type == 4 && errmsg[2] == "BANK") {
          navigate("/my/payment");
        }
      }
      toast({ type, message: response.message });
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
    } else {
      toast({
        type,
        message: "Insufficient Balance on " + currencies.currencySymbol,
      });
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
    }
  };
  const formik = useFormik({
    initialValues: {
      amount: "",
      address: "",
      tag: "",
      withdrawOTP: "",
      tfaCode: "",
    },
    validationSchema: yup.object().shape({
      amount: yup
        .number()
        .typeError("Enter enter the amount")
        .required("Amount is required"),
      address: yup
        .string("Please select the address")
        .required("Address is required")
        .test("match", async function (user_address) {
          try {
            if (user_address != undefined) {
              if (cuurrencySymbol != "PI") {
                const params = {
                  method: "POST",
                  url: `${Config.V1_API_URL}wallet/validateAddress`,
                  data: {
                    address: user_address,
                    network:
                      networkName == "Coin"
                        ? defaultCurrencyList.currencySymbol
                        : networkName,
                  },
                };
                const response = await makeRequest(params);
                return response.status == true;
              } else {
                return true;
              }
            }
          } catch (err) {
            console.log("Err", err);
          }
        }),
    }),
    onSubmit: async (values) => {
      submitWithdraw(values);
    },
  });

  useEffect(() => {
    let levelBasedLimit_copy = Object.assign({}, levelBasedLimit);
    if (myProfile && myProfile.level) {
      levelBasedLimit_copy.level = myProfile.level;

      if (withdrawLevDet && withdrawLevDet.totalDailyVolume != undefined) {
        levelBasedLimit_copy.totalDailyVolume = withdrawLevDet.totalDailyVolume;
        levelBasedLimit_copy.totalMonthlyVolume =
          withdrawLevDet.totalMonthlyVolume;
      }

      if (siteSettings && siteSettings.withdrawLevel) {
        levelBasedLimit_copy.limit =
          siteSettings.withdrawLevel["level" + myProfile.level];
      }
      setLevelBasedLimit(levelBasedLimit_copy);
    }
  }, [myProfile, siteSettings, withdrawLevDet]);

  useEffect(() => {
    const filterCurrencyList = async () => {
      const searchString = searchVal ? searchVal.toString().toLowerCase() : "";
      if (!searchString) {
        return currencyList.filter(
          (currency) =>
            currency.currencyType !== "Fiat" &&
            currency.currencyType !== "Pi-Network"
        );
      } else {
        return currencyList.filter(
          (currency) =>
            (currency.label.toLowerCase().includes(searchString) &&
              currency.currencyType !== "Fiat" &&
              currency.currencyType !== "Pi-Network") ||
            (currency.currencyName.toLowerCase().includes(searchString) &&
              currency.currencyType !== "Fiat" &&
              currency.currencyType !== "Pi-Network")
        );
      }
    };

    if (currencyList.length > 0) {
      filterCurrencyList().then((filteredData) => {
        setFillteredData(filteredData);
      });
    } else {
      getWalletCurrency_func();
    }
  }, [searchVal, currencyList]);

  let columns = [];
  let filteredItems = [];
  filteredItems = withdrawcryptoList.filter(
    (item) =>
      (item.address &&
        item.address.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.txnId &&
        item.txnId.toLowerCase().includes(filterText.toLowerCase()))
  );
  columns = [
    {
      name: "Sl.No",
      selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
      width: "70px",
    },
    {
      name: "Assets",
      selector: (row) => (
        <>
          <span className="tb-img">
            <img
              src={row?.image}
              alt="currencyImage"
              width="20px"
              height="20px"
              style={{ height: "20%", width: "20%" }}
              className="img-fluid img-res"
            />
          </span>{" "}
          {row?.currencySymbol}
        </>
      ),
      sortable: false,
    },
    {
      name: "Address",
      width: "290px",
      selector: (row) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              copyText({
                text: row.address != "" ? row.address : "",
                message: "Address copied successfully",
              })
            }
          >
            {shortAdrress(row.address)} <RiFileCopyFill />
          </span>
        </>
      ),
      sortable: false,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: false,
    },
    // {
    //   name: "Bonus",
    //   selector: (row) => bonusAmount(row),
    //   sortable: false,
    // },
    {
      name: "Fees",
      width: "150px",
      selector: (row) => row.fees.toFixed(10),
      sortable: false,
    },
    {
      name: "Txn Id",
      width: "290px",
      selector: (row) => (
        <>
          {row.txnId !== "" ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                copyText({
                  text: row.txnId !== "" ? row.txnId : "",
                  message: "Txn ID copied successfully",
                })
              }
            >
              {shortAdrress(row.txnId)} <RiFileCopyFill />
            </span>
          ) : (
            "-"
          )}
        </>
      ),
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 0
          ? "Pending"
          : row.status == 1
            ? "Approved"
            : row.status == 2
              ? "Rejected"
              : "Processing",
      sortable: false,
    },
    {
      name: "Date (UTC)",
      width: "190px",
      selector: (row) => dateFormat(row.createdDate),
    },
  ];
  const goBack = () => {
    localStorage.removeItem("networkbase");
    navigate("/my/dashboard");
  };
  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="deposit-page-top-banner">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6" onClick={goBack}>
                <GoChevronLeft className="deposit-back-button-icon" />
                <span className="deposit-text-1">Withdraw Crypto</span>
              </div>
              <div
                className="col-6 text-end"
                onClick={() =>
                  navigate("/withdraw/fiat/" + inrCurrencyList.currencySymbol)
                }
              >
                <button type="button" className="deposit-fiat-button">
                  Withdraw Fiat <HiArrowNarrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container deposit-third-section">
          <div className="row">
            <div className="col-lg-5">
              {withdrawshow == 0 && (
                <div className="d-flex flex-lg-row flex-column mt-5">
                  <div className="deposit-third-right-section">
                    <p className="deposit-text-33 password-text-44  color-blue mb-2">
                      Select coin / token
                    </p>

                    {/* <span className="deposit-text-33"> */}
                    {/* Coin / Token */}
                    {/* {defaultCurrencyList && defaultCurrencyList.currencySymbol} */}
                    {/* </span> */}
                    <div
                      className="d-flex flex-row align-items-center deposit-select-coin-section"
                      data-bs-toggle="modal"
                      data-bs-target="#selectcoinModal"
                    >
                      <div className="">
                        {defaultCurrencyList && defaultCurrencyList.image ? (
                          <>
                            <img
                              src={
                                localStorage.getItem("currencyImage")
                                  ? localStorage.getItem("currencyImage")
                                  : defaultCurrencyList &&
                                  defaultCurrencyList.image
                              }
                              className="deposit-logo-image"
                              alt="logo"
                            />
                            <span className="deposit-text-4">
                              {defaultCurrencyList &&
                                defaultCurrencyList.currencySymbol}
                            </span>
                          </>
                        ) : (
                          <img
                            src={gamecoin}
                            className="deposit-logo-image"
                            alt="logos"
                          />
                        )}
                        {/* <span className="deposit-text-5">Ethereum</span> */}
                      </div>
                      <div className="ms-auto">
                        <RiArrowDownSFill />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <form onSubmit={formik.handleSubmit}>
                {withdrawshow == 0 ? (
                  <>
                    <div className="d-flex flex-lg-row flex-column mt-5">
                      <div className="deposit-third-right-section">
                        <div className="withdraw-nav-tabs">
                          <div className="deposit-third-right-section mb-4">
                            <p className="deposit-text-33 password-text-44  color-blue mb-2">
                              Send to
                            </p>

                            {/* <span className="deposit-text-33">Address</span> */}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Address"
                              id="address"
                              name="address"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.address &&
                                Boolean(formik.errors.address)
                              }
                              helperText={
                                formik.touched.address && formik.errors.address
                              }
                              value={formik.values.address}
                            />
                            {formik.errors.address ? (
                              <small className="invalid-address error">
                                {formik.errors.address}
                              </small>
                            ) : null}
                          </div>

                          {/* {JSON.stringify(currencies)} */}

                          {currencies &&
                            currencies.currencySymbol == "XRP" &&
                            currencies.basecoin == "Coin" ? (
                            <div className="deposit-third-right-section mb-4">
                              <span className="deposit-text-33 color-blue">
                                Tag
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Destination TAG"
                                id="tag"
                                name="tag"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.tag && formik.errors.tag}
                                helperText={
                                  formik.touched.tag && formik.errors.tag
                                }
                                value={formik.values.tag}
                              />
                              {formik.errors.tag ? (
                                <small className="invalid-tag error">
                                  {formik.errors.tag}
                                </small>
                              ) : null}
                            </div>
                          ) : (
                            ""
                          )}

                          <span className="deposit-text-33 color-blue">
                            Network
                          </span>
                          <div
                            className="d-flex flex-row align-items-center deposit-select-coin-section"
                            data-bs-toggle="modal"
                            data-bs-target="#withdrawnetworkModal"
                          >
                            <div className="">
                              <span className="deposit-text-4"></span>
                              <span className="deposit-text-5">
                                {networkName == "Coin"
                                  ? defaultCurrencyList.currencySymbol
                                  : networkName}
                              </span>
                            </div>
                            <div className="ms-auto">
                              <RiArrowDownSFill />
                            </div>
                          </div>
                          <div className="deposit-third-right-section mb-4 mt-2">
                            <span className="deposit-text-33 color-blue">
                              Amount
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              placeholder={
                                "Minimum " +
                                parseFloat(
                                  minwithamt.toFixed(currencies.siteDecimal)
                                ) +
                                " " +
                                (defaultCurrencyList &&
                                  defaultCurrencyList.currencySymbol)
                              }
                              name="amount"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.amount && formik.errors.amount
                              }
                              helperText={
                                formik.touched.amount && formik.errors.amount
                              }
                              value={formik.values.amount}
                            />
                            {formik.errors.amount ? (
                              <small className="invalid-amount error">
                                {formik.errors.amount}
                              </small>
                            ) : null}
                          </div>
                          <button
                            type="submit"
                            className="btn btn-col w-100 mt-4 alt-submit-txt-btn"
                          >
                            Withdraw
                          </button>

                          <div className="row mt-4">
                            <div className="col-6">
                              <span className="deposit-text-5">
                                {networkName
                                  ? networkName
                                  : defaultCurrencyList &&
                                  defaultCurrencyList.currencySymbol}{" "}
                                balance
                              </span>
                              <br />
                              <span className="deposit-text-66">
                                {parseFloat(
                                  userbalance?.toFixed(currencies?.siteDecimal)
                                )}{" "}
                                {defaultCurrencyList &&
                                  defaultCurrencyList.currencySymbol}{" "}
                                - {defaultCurrencyList.basecoin}
                              </span>
                            </div>
                            <div className="col-6">
                              <span className="deposit-text-5">
                                Minimum withdrawal
                              </span>
                              <br />
                              <span className="deposit-text-66">
                                {parseFloat(
                                  minwithamt.toFixed(currencies.siteDecimal)
                                )}{" "}
                                {defaultCurrencyList &&
                                  defaultCurrencyList.currencySymbol}{" "}
                                - {defaultCurrencyList.basecoin}
                              </span>
                            </div>
                          </div>

                          <div className="row mt-3">
                            {levelBasedLimit &&
                              levelBasedLimit.totalDailyVolume != undefined &&
                              levelBasedLimit.limit &&
                              levelBasedLimit.limit.dailyVolume != undefined ? (
                              <div className="col-6">
                                <span className="deposit-text-5">
                                  24h remaining limit
                                </span>
                                <br />
                                <span className="deposit-text-66">
                                  {levelBasedLimit.limit.dailyVolume == 0
                                    ? "No limit"
                                    : (
                                      levelBasedLimit.limit.dailyVolume -
                                      levelBasedLimit.totalDailyVolume
                                    )?.toFixed(2) +
                                    " USD/" +
                                    levelBasedLimit.limit.dailyVolume +
                                    " USD"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {levelBasedLimit &&
                              levelBasedLimit.totalMonthlyVolume != undefined &&
                              levelBasedLimit.limit &&
                              levelBasedLimit.limit.monthlyVolume != undefined ? (
                              <div className="col-6">
                                <span className="deposit-text-5">
                                  Monthly remaining limit
                                </span>
                                <br />
                                <span className="deposit-text-66">
                                  {levelBasedLimit.limit.monthlyVolume == 0
                                    ? "No limit"
                                    : (
                                      levelBasedLimit.limit.monthlyVolume -
                                      levelBasedLimit.totalMonthlyVolume
                                    )?.toFixed(2) +
                                    " USD/" +
                                    levelBasedLimit.limit.monthlyVolume +
                                    " USD"}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="row mt-3">
                            <div className="col-6">
                              <span className="deposit-text-5">
                                Network fee
                              </span>
                              <br />
                              <span className="deposit-text-66">
                                {parseFloat(
                                  fees.toFixed(currencies.siteDecimal)
                                )}{" "}
                                {feeType == "1" ? "%" : ""}{" "}
                                {defaultCurrencyList &&
                                  defaultCurrencyList.currencySymbol}{" "}
                                - {defaultCurrencyList.basecoin}
                                {/* 0.0000043 ~ 0.0002 BTC */}
                              </span>
                            </div>
                            <div className="col-6">
                              <span className="deposit-text-5">
                                Network fee (-) from amount
                              </span>
                              <br />
                              <span className="deposit-text-66">
                                {/* {fees.toFixed(currencies.siteDecimal)} {feeType == "1" ? '%' : ''} {defaultCurrencyList && defaultCurrencyList.currencySymbol} */}
                                {/* 0.0000043 ~ 0.0002 BTC */}
                                {feeType == "1"
                                  ? parseFloat(
                                    (
                                      (formik.values.amount * fees) /
                                      100
                                    ).toFixed(currencies.siteDecimal)
                                  )
                                  : formik.values.amount == 0
                                    ? (0).toFixed(currencies.siteDecimal)
                                    : (formik.values.amount - fees).toFixed(
                                      currencies.siteDecimal
                                    )}{" "}
                                {defaultCurrencyList &&
                                  defaultCurrencyList.currencySymbol}{" "}
                                - {defaultCurrencyList.basecoin}
                              </span>
                            </div>
                            {particularcurrency.withdrawNotes &&
                              particularcurrency.withdrawNotes != "NA" ? (
                              <div className="row mt-3">
                                <div className="col-12">
                                  <span className="deposit-text-5">Notes</span>
                                  <br />
                                  <span className="deposit-text-66">
                                    {particularcurrency.withdrawNotes}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-lg-row flex-column mt-5">
                      <div className="deposit-third-right-section">
                        <div className="withdraw-nav-tabs">
                          <div className="deposit-third-right-section mb-4">
                            <div className="row">
                              <span className="deposit-text-33">
                                Enter Email OTP
                              </span>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control w-50"
                                  aria-describedby="button-addon2"
                                  placeholder="Enter Email OTP"
                                  id="withdrawOTP"
                                  name="withdrawOTP"
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  onKeyDown={(evt) =>
                                    evt.key === "e" && evt.preventDefault()
                                  }
                                  onKeyPress={(e) => {
                                    if (
                                      !/^\d$/.test(e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  error={
                                    formik.touched.withdrawOTP &&
                                    formik.errors.withdrawOTP
                                  }
                                  helperText={
                                    formik.touched.withdrawOTP &&
                                    formik.errors.withdrawOTP
                                  }
                                />
                              </div>
                              <button
                                id="button-addon2"
                                className="btn btn-phone-number-verification-code w-auto ms-auto"
                                type="button"
                                disabled={
                                  isLoading || timer_resendOtp > 0
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  submitWithdraw(formik.values, "resendOTP")
                                }
                              >
                                Resend OTP
                                {timer_resendOtp > 0 ? (
                                  <span className="timeLeft">
                                    ({timer_resendOtp}s)
                                  </span>
                                ) : (
                                  ""
                                )}
                              </button>
                              {formik.errors.withdrawOTP ? (
                                <small className="invalid-otp error">
                                  {formik.errors.withdrawOTP} <br />
                                </small>
                              ) : null}
                            </div>
                          </div>

                          <div className="deposit-third-right-section mb-4">
                            <div className="row">
                              <span className="deposit-text-33">
                                Enter 2FA Code
                              </span>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control w-50"
                                  placeholder="Enter 2FA Code"
                                  id="tfaCode"
                                  name="tfaCode"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  onKeyDown={(evt) =>
                                    evt.key === "e" && evt.preventDefault()
                                  }
                                  onKeyPress={(e) => {
                                    if (
                                      !/^\d$/.test(e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  error={
                                    formik.touched.tfaCode &&
                                    formik.errors.tfaCode
                                  }
                                  helperText={
                                    formik.touched.tfaCode &&
                                    formik.errors.tfaCode
                                  }
                                />
                              </div>

                              {formik.errors.tfaCode ? (
                                <small className="invalid-otp error">
                                  {formik.errors.tfaCode} <br />
                                </small>
                              ) : null}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <button
                                type="submit"
                                className="btn btn-col w-100 mt-4 alt-submit-txt-btn"
                              // disabled={(isLoading) ? true : false}
                              >
                                Withdraw
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                type="button"
                                className="btn btn-col w-100 mt-4"
                                onClick={() => setWithdrawshow(0)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>

        <div className="container deposit-fourth-section mt-4 mb-5">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <h1 className="deposit-text-11 color-blue">Recent Withdraw</h1>
              <div className="col-lg-5 mt-3">
                <Form>
                  <FormGroup>
                    <Label for="exampleSelect">Search</Label>
                    <Input
                      id="search"
                      type="text"
                      placeholder="Filter By Transaction id"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </FormGroup>
                </Form>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                persistTableHead
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade withdraw-crypto-modal"
        id="selectcoinModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header ">
              <h4 className="modal-title" id="exampleModalLabel">
                Select coin to withdraw
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                id="myInput2"
                type="text"
                placeholder="Search here..."
                autoComplete="off"
                onChange={(e) => setSearchVal(e.target.value)}
              />
              <div className="modal-2-overflow-scroll">
                <ul className="withdraw-crypto-modal-list">
                  {fillteredData.length > 0 ? (
                    fillteredData.map((currency, i) => {
                      if (currency.label != "INR" && currency.label != "EUR") {
                        return (
                          <li
                            onClick={() => {
                              console.log("currencycurrency", currency)
                              handleCurrencyChange(currency.label);
                            }}
                          >
                            <a data-bs-dismiss="modal">
                              <div className="d-flex flex-row align-items-center withdraw-crypto-list-items">
                                <div className="">
                                  <img
                                    src={currency.image}
                                    alt="logo"
                                    className="withdraw-crypto-modal-images"
                                  />
                                </div>
                                <div className="">
                                  <span className="withdraw-crypto-modal-text-1">
                                    {currency.label}
                                  </span>
                                  <br />
                                  <span className="withdraw-crypto-modal-text-2">
                                    {currency.currencyName}
                                  </span>
                                </div>
                                <div className="ms-auto">
                                  <span className="withdraw-crypto-modal-text-3">
                                    Balance
                                  </span>
                                  <span className="withdraw-crypto-modal-text-4">
                                    {currency.balance}
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      }
                    })
                  ) : (
                    <>
                      <p className="text-center">No Coin Found</p>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade withdraw-crypto-network-modal"
        id="withdrawnetworkModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Select network
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body modal-2-overflow-scroll">
              <p className="select-network-text-1">
                Please ensure your receiving platform supports the token and
                network you are withdrawing. If you are unsure, kindly check
                with the receiving platform first.
              </p>
              <ul className="withdraw-crypto-modal-list">
                {networkList.length > 0 &&
                  networkList.map((item, i) => {
                    // if (item._id == defaultNetwork) {
                    return (
                      <li
                        selected={true}
                        onClick={() =>
                          handlenetworkChange(item._id, item.basecoin)
                        }
                      >
                        <a data-bs-dismiss="modal">
                          <div className="d-flex flex-row withdraw-modal-network-sizing">
                            <div>
                              <p className="deposit-modal-coin-heading">
                                {networkName == item.basecoin
                                  ? networkName == "Coin"
                                    ? defaultCurrencyList.currencySymbol
                                    : networkName
                                  : item.basecoin}
                              </p>
                              {/* <p className="withdraw-modal-coin-muted-text">
                              BNB Smart Chain (
                              <span className="withdraw-modal-coin-muted-text-1">
                                BEP20
                              </span>
                              )
                            </p> */}
                            </div>
                            <div className="ms-auto text-end">
                              {/* <p className="withdraw-modal-coin-muted-text">
                              Arrival time ≈{" "}
                              <span className="withdraw-modal-coin-muted-text-1">
                                {" "}
                                5 mins{" "}
                              </span>
                            </p>
                            <p className="withdraw-modal-coin-muted-text">
                              fee{" "}
                              <span className="withdraw-modal-coin-muted-text-1">
                                0.0000043 BTC( ≈ $0.08969783 )
                              </span>
                            </p> */}
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                    // }
                    // else {
                    //   return (
                    //     <li onClick={() => handlenetworkChange(networkName == item.basecoin ? networkName : item._id)}>
                    //       <a data-bs-dismiss="modal">
                    //         <div className="d-flex flex-row withdraw-modal-network-sizing">
                    //           <div>
                    //             <p className="deposit-modal-coin-heading">{networkName == item.basecoin ? networkName : item.basecoin}</p>
                    //             <p className="withdraw-modal-coin-muted-text">

                    //             </p>
                    //           </div>
                    //           <div className="ms-auto text-end">

                    //           </div>
                    //         </div>
                    //       </a>
                    //     </li>
                    //   )
                    // }
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
