const { default: jwtDecode } = require("jwt-decode");

exports.JWTDecode = (token) => {
    if (token !== "") {
        const decodedToken = jwtDecode(token);
        const currDate = new Date();
        const currTimeStamp = currDate.getTime();
        if (decodedToken.exp * 1000 > currTimeStamp) {
            return decodedToken
        } else {
            return {
                status: false, message: "Something Went Wrong!", toastType: "error"
            }
        }
    } else {
        return false
    }
};