import React, { useState, useEffect } from "react";
import "../assets/style.css";
import { GoChevronLeft } from "react-icons/go";
import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import Aboutimg from "../assets/images/6220376-ai.png";
import {
    BrowserRouter as Router, useNavigate, useParams
} from "react-router-dom";
import { SocialIcon } from 'react-social-icons'
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { TiArrowMinimise } from "react-icons/ti";
import { BsCurrencyExchange } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { makeRequest } from "../core/services/v1/request";
import Config from "../core/config/index";



export default function About(props) {
    const navigate = useNavigate();
    const [socialMediaLinks, setSocialMediaLinks] = useState({});
    console.log("socialMediaLinks", socialMediaLinks)
    useEffect(() => {
        getSiteSettings();
    }, [])

    async function backButton() {
        try {
            const length = window.history.length;
            if (length > 1) {
                window.history.back();
            } else {
                navigate("/");
            }
        } catch (err) { }
    }

    async function getSiteSettings() {
        const params = {
            url: `${Config.V1_API_URL}common/siteSettings`,
            method: 'GET',
        }
        const response = (await makeRequest(params));
        // console.log("response--",response);
        if (response.status) {
            setSocialMediaLinks(response.message);
        } else {
            setSocialMediaLinks({});
        }
    }


    return (
        <div>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className="getx-new-about-csssabv">
                <div className="deposit-page-top-banner">
                    <div className="deposit-hero-section">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <GoChevronLeft className="deposit-back-button-icon" onClick={() => backButton()} />
                                    <span className="deposit-text-1">About</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='top-banner-home-section-5 bg-transparent py-0' style={{ background: "none" }}>
                    <div className="getx-new-about-csssabv-top-banner">
                        <div className='container py-lg-5 pt-5'>
                            <div className='row g-4 align-items-center text-center'>
                                <div className='col-lg-12'>
                                    <h2 className='banner-text-3 mb-0'>GetX</h2>
                                    <h2 className='banner-text-3 mb-0'>‘Where FIAT meets the Future’</h2>
                                    <p className='banner-text-7 mt-4'>Welcome to GetX - Your Go-To Place for Crypto Trading.  Through our platform global users can buy, sell, or trade cryptocurrencies including Bitcoin (BTC), Ethereum (ETH), Shiba INU (SHIB), Binance Coin (BNB), Tether (USDT), Ripple (XRP), etc.   </p>
                                </div>
                            </div>
                        </div>
                        <div className='container-fluid pb-5'>
                            <div class="row row-cols-2 row-cols-md-4 g-4 mt-4 col-lg-11 mx-auto">
                                <div class="col">
                                    <div class="card h-100">
                                        <div class="card-body">
                                            <VscWorkspaceTrusted style={{ fontSize: "24px" }} />
                                            <h5 class="card-title" style={{ fontSize: "2.4vw" }}>1M+</h5>
                                            <p class="card-text" style={{ textTransform: "uppercase" }}>Users Trust Us</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <div class="card-body">
                                            <BsCurrencyExchange style={{ fontSize: "24px" }} />
                                            <h5 class="card-title" style={{ fontSize: "2.4vw" }}>200+</h5>
                                            <p class="card-text" style={{ textTransform: "uppercase" }}>Crypto Pairs</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <div class="card-body">
                                            <TiArrowMinimise style={{ fontSize: "24px" }} />
                                            <h5 class="card-title" style={{ fontSize: "2.4vw" }}>10M+</h5>
                                            <p class="card-text" style={{ textTransform: "uppercase" }}>Traded</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <div class="card-body">
                                            <FaHandshake style={{ fontSize: "24px" }} />
                                            <h5 class="card-title" style={{ fontSize: "2.4vw" }}>24/7</h5>
                                            <p class="card-text" style={{ textTransform: "uppercase" }}>Support</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container py-5'>
                        <div class="row g-4 justify-content-between">
                            <div class="col-lg-5">
                                <img src={Aboutimg} alt="Aboutimg" className="about-new-getx-nnn-img" />
                            </div>
                            <div class="col-lg-6">
                                <h2 className='banner-text-3 mb-0'>Our Goal</h2>
                                <p className='banner-text-7 mt-4 mb-0'>We want to make trading crypto easy and safe for everyone. We want you to feel confident and secure when using our platform. That's why we keep things simple and ensure your transactions are always secure.</p>
                                <h4 className="mt-4 fw-bold">Follow us on</h4>
                                <div className="d-flex gap-3">
                                    <a href={socialMediaLinks?.twitterLink} target="blank" rel="noopener noreferrer"><SocialIcon network="x" bgColor="#00ACED" /></a>
                                    <a href={socialMediaLinks?.youtubeLink} target="blank" rel="noopener noreferrer"><SocialIcon network="youtube" /></a>
                                    <a href={socialMediaLinks?.linkedinLink} target="blank" rel="noopener noreferrer"><SocialIcon network="linkedin" /></a>
                                    <a href={socialMediaLinks?.facebookLink} target="blank" rel="noopener noreferrer"><SocialIcon network="facebook" /></a>
                                    <a href={socialMediaLinks?.instagramLink} target="blank" rel="noopener noreferrer"><SocialIcon network="instagram" /></a>
                                    <a href={socialMediaLinks?.telegramLink} target="blank" rel="noopener noreferrer"><SocialIcon network="telegram" /></a>
                                </div>
                                <h4 className="mt-4 fw-bold">Contact us</h4>

                                <h5>Plot No. 29, 30, Iswarya Nagar,</h5>
                                <h5>Madakkulam, Tamil Nadu 625003, India</h5>
                                <h5>{socialMediaLinks?.supportEmail}</h5>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}
