import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/Logo.png';
import { FaInstagram, FaTwitter, FaLinkedinIn, FaDiscord, FaTelegramPlane, FaYoutube, FaMediumM } from 'react-icons/fa';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
} from "react-router-dom";
import Getx from "../../assets/new-getx-images/getx.png";
import QRcode from "../../assets/images/qr.png";
import Appstore from "../../assets/images/app-store.png";
import Googleplay from "../../assets/images/google-play.png";
import { useContextData } from '../../core/context/index';
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import {
    getHomeCMS
} from '../../core/services/all.api';

import Config from "../../core/config/index";
import LawEnforcement from "../../assets/images/pdf/Regulations-Pertaining-to-Law-Enforcement-and-Lega.pdf";
import WhitePaper from "../../assets/whitepaper/whitepaper.pdf"
import DummyQR from "../../assets/images/dummy-QR.png";
import { getCookie } from '../../core/helper/cookie';
import { makeRequest } from '../../core/services/v1/request';
import QRCode from 'react-qr-code';

const Footer = () => {
    const navigate = useNavigate();
    const { siteSettings } = useContextData();
    const [cmsDynData, setCmsDynData] = useState({});
    const [socialMediaLinks, setSocialMediaLinks] = useState({});
    const token = getCookie("userToken");
    const path = window.location.pathname.split("/").pop();

    useEffect(() => {
        getFooterCMSCall();
        getSiteSettings();
    }, []);

    const isValidHttpUrl = async (val = "") => {
        let url;
        try {
            url = new URL(val);
        } catch (_) {
            return false;
        };
        return url.protocol === "http:" || url.protocol === "https:";
    };

    const navigateTo = async (linkVal) => {
        if (linkVal) {
            if (await isValidHttpUrl(linkVal)) {
                window.open(linkVal);
            }
            else {
                navigate(linkVal, true);
            };
        };
    };

    async function getSiteSettings() {
        const params = {
            url: `${Config.V1_API_URL}common/siteSettings`,
            method: 'GET',
        };
        const response = (await makeRequest(params));
        if (response.status) {
            setSocialMediaLinks(response.message);
        } else {
            setSocialMediaLinks({});
        };
    };

    const getFooterCMSCall = async () => {
        const payload = { from: "footer" };
        const resp = await getHomeCMS({ payload });
        if (resp) {
            const {
                getcmsDetails = [],
                status = false
            } = resp;
            if (status) {
                let CMSList = getcmsDetails.filter((elem) => (elem.identify == "CMS"));
                const updateCmdData = {
                    CMSList: (CMSList && CMSList.length > 0) ? CMSList : []
                };
                setCmsDynData(updateCmdData);
            }
        }
    }

    return (
        <>
            <section className='footer-2-bottom-section'>
                <div className='container-fluid col-lg-11'>
                    <div className='row g-4'>
                        <div className='col-lg-3'>
                            <img src={Getx} alt='footer-logo' className='footer-logo-image' />

                            <div className='mt-lg-4 mt-2'>
                                <p className='new-footer-text-1'>DOWNLOAD OUR APP TO STAY CONNECT 24/7</p>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-lg-4 col-4'>
                                    <QRCode value="https://getx.exchange/" className="img-fluid bg-white p-2" />
                                </div>
                                <div className='col-lg-8'>
                                    <p className='new-footer-text-2 mb-0'>Scan to download APP</p>
                                    <p className='new-footer-text-2 mb-0'>Android and iOS supported</p>
                                </div>
                            </div>
                            <div className='row align-items-center mt-4'>
                                <div className='col-lg-5 col-4'>
                                    <a href='/coming-soon'><img style={{ borderRadius: "14px" }} src={Googleplay} alt='Googleplay' /></a>
                                </div>
                                <div className='col-lg-5 col-4'>
                                    <a href='/coming-soon'><img style={{ borderRadius: "14px" }} src={Appstore} alt='Appstore' /></a>
                                </div>
                            </div>
                            <div className='top-banner-home-section mt-4'>
                                <p className='new-footer-text-3 mb-1'>Stay turned for Updates</p>
                                {token ? (
                                    <button onClick={() => navigate('/spot/WBTC_USDT')} className='banner-button-1 px-2'>Join Now</button>
                                ) : (
                                    <button onClick={() => navigate('/register')} className='banner-button-1 px-2'>Join Now</button>
                                )}
                                {/* <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Email Address" />
                                    <button class="banner-button-1 px-2" type="button" id="button-addon1">Submit</button>
                                </div> */}
                            </div>
                            <div class="social-menu-new-footer mt-5">
                                <ul className='p-0'>
                                    <li><a href={socialMediaLinks?.facebookLink} target="blank" rel="noopener noreferrer"><FaFacebookF className='fab' /></a></li>
                                    <li><a href={socialMediaLinks?.twitterLink} target="blank" rel="noopener noreferrer"><FaXTwitter className='fab' /></a></li>
                                    <li><a href={socialMediaLinks?.telegramLink} target="blank" rel="noopener noreferrer"><FaTelegramPlane className='fab' /></a></li>
                                    <li><a href={socialMediaLinks?.linkedinLink} target="blank" rel="noopener noreferrer"><FaLinkedinIn className='fab' /></a></li>
                                </ul>
                                <ul className='p-0'>
                                    <li><a href={socialMediaLinks?.youtubeLink} target="blank" rel="noopener noreferrer"><FaYoutube className='fab' /></a></li>
                                    <li><a href={socialMediaLinks?.instagramLink} target="blank" rel="noopener noreferrer"><FaInstagram className='fab' /></a></li>
                                    <li><a href={socialMediaLinks?.discordLink} target="blank" rel="noopener noreferrer"><FaDiscord className='fab' /></a></li>
                                    <li><a href={socialMediaLinks?.mediumLink} target="blank" rel="noopener noreferrer"><FaMediumM className='fab' /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div class="list-group footer-typo-new ">
                                <h4 class="mb-lg-2 footer-typo-head">About Us</h4>
                                <a className='footer-typo-list' href='/about'>GETX</a>
                                <a className='footer-typo-list' href='/authenticity-check'>Authenticity Check</a>
                                <a className='footer-typo-list' href='/authenticity-check'>Authorized Payment Personnel</a>
                                <a className='footer-typo-list' href='/affiliate'>GETX Affiliate PROGRAM</a>
                                <a className='footer-typo-list' href={"/coming-soon"} target='_blank' >Whitepaper</a>
                                {/* <a className='footer-typo-list' href={Config.coinListing} target={"_blank"}>Coin Listing</a> */}
                            </div>
                            <div class="list-group footer-typo-new mt-5">
                                <h4 class="mb-lg-2 footer-typo-head">Legal</h4>
                                <a className='footer-typo-list' href='/privacy-policy'>Privacy Policy</a>
                                <a className='footer-typo-list' href='/aml-policy'>AML Policy</a>
                                <a className='footer-typo-list' href='/cookie-statement'>Cookie Statement</a>
                                <a className='footer-typo-list' href='/risk-warning'>Risk Warning</a>
                                <a className='footer-typo-list' href='/terms'>Terms Of Use</a>
                                <a className='footer-typo-list' href='/refund-policy'>Refund Policy</a>
                                {/* {
                                    cmsDynData && cmsDynData.CMSList && cmsDynData.CMSList.length > 0 &&
                                    cmsDynData.CMSList.map((row, i) => {
                                        if (row.title && row.link) {
                                            return (
                                                <Link className='footer-typo-list' to={row.link}>
                                                    {row.title}
                                                </Link>
                                            )
                                        }
                                    })
                                } */}
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div class="list-group footer-typo-new ">
                                <h4 class="mb-lg-2 footer-typo-head">Resources</h4>
                                <a className='footer-typo-list' href="/coin-status">Coin Status</a>
                                <a className='footer-typo-list' href="/referralprogram">Referral Program</a>
                                <a className='footer-typo-list' href="/coin-listing">List Your Token / Coin</a>
                                <a className='footer-typo-list' href="/spot/WBTC_USDT">Trade</a>
                                <a className='footer-typo-list' href="/fees">Fees</a>
                                <a className='footer-typo-list' href="/launch-pad">Fundraising/crowdfunding</a>
                                <a className='footer-typo-list'
                                    // href="/prediction-trade"
                                    href="javascript:void(0);"
                                >Trade Contest (coming soon)</a>
                                {/* <a className='footer-typo-list' href="/apipage">API Documentation</a> */}
                            </div>
                            <div class="list-group footer-typo-new mt-5">
                                <h4 class="mb-lg-2 footer-typo-head">Support (24X7)</h4>
                                <a className='footer-typo-list' href="/contact-support">Help Desk</a>
                                <a className='footer-typo-list' href="/support">Submit a Ticket</a>
                                <a className='footer-typo-list' href="mailto:info@getx.exchange?subject=Feedback">Give Us Feedback</a>
                                <a className='footer-typo-list' href={LawEnforcement} target='_blank'>For Law Enforcement</a>

                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div class="list-group footer-typo-new">
                                <h4 class="mb-lg-2 footer-typo-head">Trade Crypto</h4>
                                <a className='footer-typo-list' href="/spot/WBTC_INR">Buy Bitcoin in India</a>
                                <a className='footer-typo-list' href="/spot/ETH_INR">Buy Ethereum in India</a>
                                <a className='footer-typo-list' href="/spot/USDT_INR">Buy Tether in India</a>
                                <a className='footer-typo-list' href="/spot/GETX_INR">Buy GETX in India</a>
                                <a className='footer-typo-list' href="/spot/DCX_INR">Buy D-coin in India</a>
                                <a className='footer-typo-list' href="/spot/TRX_INR">Buy Tron in India</a>
                                <a className='footer-typo-list' href="/spot/ADA_INR">Buy Cardano in India</a>
                                <a className='footer-typo-list' href="/spot/POL_INR">Buy Polygon in India</a>
                                <a className='footer-typo-list' href="/spot/SOL_INR">Buy Solana in India</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <div className="container-fluid  pt-2 pb-5">
                <div className="row">
                    {/* <div className='container w-75'>
                        <div className="row">
                            <div className="col-lg-12 my-1">
                                <center ><p><small className="text-danger">Disclaimer: Crypto products and NFTS are unregulated and can be highly risky. There may be no regulatory recourse for any loss from such transactions.
                                    The disclaimer is for all those who are using this platform whose age is above 18 years. Below 18 years age is not applicable to use this application.</small></p></center>
                            </div>
                        </div>
                    </div> */}
                    <div className="d-flex flex-lg-column flex-column justify-content-center">
                        {
                            path !== "transactionHistory" && (
                                siteSettings &&
                                <div className="fs-14 text-center">
                                    {siteSettings?.copyRights}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
