import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { dateFormat, showNumber } from "../core/helper/date-format";
import DataTable from "react-data-table-component";
import { getProfitList_API } from "../core/services/all.api";
import notFound from "../assets/images/file.png"
import "../pagination.css";
import Config from "../core/config";
import { makeRequest } from "../core/services/v1/request";
import { getOrderDetail_API } from "../core/services/all.api";
import TradeHistoryDetail from "./exchange-trade/separate/TradeHistoryDetail";
import { HiRefresh } from "react-icons/hi";
function SpotTradeHistory() {
  const [tradeHistory, setTradeHistory] = useState([]);
  const [traHisDetails, setTraHisDetails] = useState({});
  const [isloadingData, setIsloadingData] = useState({});
  const [markets, setMarkets] = useState([]);

  //pagination states
  const [totalHistory, settotalHistory] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const [currentPage_SpostHistory, setCurrentPage_SpostHistory] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [side, setSide] = useState("");
  const [pair, setPair] = useState("")
  const [marketType, setMarketType] = useState("");
  const [status, setStatus] = useState("");
  const loadingChange = (data = {}) => {
    const dataCopy = Object.assign({}, isloadingData);
    dataCopy[data.key] = data.value;
    setIsloadingData(dataCopy);
  };

  const getProfitList_Call = async () => {
    const apiData = {
      limit: currentPage_SpostHistory,
      offset: recordsPerPage * (currentPage_SpostHistory - 1),
    };
    loadingChange({ key: "getProfitList_API", value: true });
    const resp = await getProfitList_API(apiData);
    loadingChange({ key: "getProfitList_API", value: false });
    if (resp.status === true) {
      settotalHistory(resp.count);
    }
  };

  useEffect(() => {
    getTableData();
    getMarketPairs();
    getProfitList_Call();
  }, []);
  async function handlePageChange(currentPage_tradeHis) {
    setCurrentPage_SpostHistory(currentPage_tradeHis);
    try {
      let data = {
        limit: recordsPerPage,
        offset: recordsPerPage * (currentPage_tradeHis - 1),
      };
      if (side !== "") {
        data.side = side;
      };
      if (marketType !== "") {
        data.marketType = marketType;
      };
      if (pair !== "") {
        data.pair = pair;
      };
      if (status !== "") {
        data.status = status
      }
      const params = {
        url: `${Config.V2_API_URL}trade/profit/list`,
        method: "POST",
        body: data,
      };
      const response = await makeRequest(params);
      if (response.status && response.list) {
        settotalHistory(response.count);
        setTradeHistory(response.list);
      }
    } catch (err) { }
  }

  const getTableData = async () => {
    try {
      const data = {
        limit: recordsPerPage,
        offset: 0
      };
      const params = {
        url: `${Config.V2_API_URL}trade/profit/list`,
        method: "POST",
        body: data,
      };
      const response = await makeRequest(params);
      if (response.status && response.list) {
        settotalHistory(response.count);
        setTradeHistory(response.list);
      }
    } catch (error) {
      console.log("tablem  error");
    }
  }

  const getOrderDetail_call = async (orderId) => {
    setTraHisDetails({});
    const data = {
      payload: {
        orderId: orderId,
      },
    };
    const resp = await getOrderDetail_API(data);
    if (resp.status === true && resp.response) {
      setTraHisDetails(resp.response);
    }
  };

  function truncateToDecimal(value, decimal = 0) {
    const factor = Math.pow(10, decimal);
    return Math.floor(value * factor) / factor;
  }
  function decimalValue(value, decimal = 0) {
    if (decimal === 0) {
      return showNumber(parseFloat(value));
    }
    return showNumber(truncateToDecimal(parseFloat(value), decimal).toFixed(decimal));
  }

  async function getMarketPairs() {
    try {
      const params = {
        url: `${Config.V1_API_URL}trade/getAllPairs`,
        method: 'GET',
      }
      const response = (await makeRequest(params));
      if (response.status) {
        const marketPairs = response.data;
        console.log('marketPairs', marketPairs)
        setMarkets(marketPairs);
      }
    } catch (err) {
      console.log("Market Error:");
    }
  };

  useEffect(() => {
    handlePageChange(currentPage_SpostHistory);
  }, [side, marketType, pair, status, currentPage_SpostHistory, recordsPerPage]);

  const handleRefreshClick = () => {
    setIsRefreshing(true);
    setTimeout(() => {
      setIsRefreshing(false);
    }, 4000);
  };

  const columns = [
    {
      name: 'S.No',
      selector: (row, index) => recordsPerPage * (currentPage_SpostHistory - 1) + index + 1,
      width: '60px',
    },
    {
      name: 'Date',
      selector: row => dateFormat(row.MappingOrders.dateTime),
    },
    {
      name: 'Pair',
      selector: row => row.MappingOrders.pairName ? (
        <Link to={"/spot/" + row.MappingOrders.pairName}>
          {row.MappingOrders.pairName.replace("_", "/")}
        </Link>
      ) : (
        ""
      ),
    },
    {
      name: 'Side',
      selector: row => (
        <span className={`capitalizeText ${row.type === "buy" ? "color-green" : "color-red"}`}>
          {row.type}
        </span>
      ),
    },
    {
      name: 'Price',
      selector: row => decimalValue(row.MappingOrders.tradePrice, row.MappingOrders.pair.priceDecimal),
    },
    {
      name: 'Filled',
      selector: row => row.MappingOrders.filledAmount > 0 ? row.MappingOrders.filledAmount.toFixed(row.MappingOrders.pair.amountDecimal) : "---",
    },
    {
      name: 'Total',
      selector: row => decimalValue((row.MappingOrders.total), row.MappingOrders.pair.totalDecimal),
    },
    {
      name: 'Fees',
      selector: row => row.MappingOrders.status !== "cancelled" ?
        `${decimalValue(
          row.MappingOrders.Profit[0]?.totalFees || 0,
          row.MappingOrders.Profit[0]?.userFeeReduced !== "tradeFeeVoucher" &&
            row.MappingOrders.Profit[0]?.userFeeReduced !== "fanToken" ?
            row.MappingOrders.Profit[0]?.currency.siteDecimal :
            row.type === "buy" ?
              row.MappingOrders.pair.amountDecimal :
              row.MappingOrders.pair.priceDecimal
        )} ${row.MappingOrders.Profit[0]?.currency.currencySymbol || ''}`
        : "---"
    },
    {
      name: 'Fee Type',
      selector: row => {
        if (row.MappingOrders.status !== "cancelled") {
          let userFeeReduced = row.MappingOrders.Profit && row.MappingOrders.Profit[0] ? row.MappingOrders.Profit[0].userFeeReduced : "";
          if (userFeeReduced === "tradeFeeVoucher") {
            userFeeReduced = "Voucher";
          } else if (userFeeReduced === "fanToken") {
            userFeeReduced = Config.FanTknSymbol;
          } else if (userFeeReduced === "respective") {
            userFeeReduced = "Respective";
          };
          return userFeeReduced;
        } else {
          return "---"
        };
      },
    },
    {
      name: 'Status',
      cell: row => (
        <button
          className="popup-modal-button-my-orders capitalizeText py-2 px-0 w-100 text-center"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            getOrderDetail_call(row._id);
          }}
        >
          {row.MappingOrders.status}
        </button>
      ),
    },
  ];

  const data = tradeHistory && tradeHistory.length > 0 ? tradeHistory : [];
  return (
    <div>
      <div className="container-fluid px-0 new-dev-spot-table">
        <div className="row">
          <div className="col-12">
            <h3 className="head-profile">Trade History</h3>

            <ul className="d-flex market-trade-tabs flex-row gap-3 p-0 bg-transparent border-0 flex-wrap" id="pills-tab" role="tablist">
              <select className='form-select' value={pair} onChange={(e) => setPair(e.target.value)} style={{ cursor: 'pointer' }}>
                <option value={""}>All Pairs</option>
                {
                  markets.map((data) => {
                    return (
                      <option value={data.pair}>{data.pair}</option>
                    )
                  })
                }
              </select>

              <select className='form-select' value={side} onChange={(e) => setSide(e.target.value)} style={{ cursor: 'pointer' }}>
                <option value="" >All Side</option>
                <option value={"buy"}>buy</option>
                <option value={"sell"}>sell</option>
              </select>

              <select className='form-select' value={marketType} onChange={(e) => setMarketType(e.target.value)} style={{ cursor: 'pointer' }}>
                <option value="" >All Order Type</option>
                <option value={"limit"}>Limit</option>
                <option value={"market"}>Market</option>
                <option value={"stop"}>Stop Limit</option>
              </select>

              <select className='form-select' value={status} onChange={(e) => setStatus(e.target.value)} style={{ cursor: 'pointer' }}>
                <option value="" >All Status</option>
                <option value={"filled"}>Filled</option>
                <option value={"cancelled"}>Cancelled</option>
              </select>

              <span className={`refresh-history fw-b cursor-poniter ${isRefreshing ? 'rotating' : ''}`} onClick={() => {
                handleRefreshClick()
                setPair("");
                setSide("");
                setMarketType("");
                setStatus("");
              }}><HiRefresh style={{ fontSize: 30, height: 38 }} /> </span>
            </ul>
            <DataTable
              columns={columns}
              data={data}
              persistTableHead
              pagination
              paginationServer
              paginationPerPage={recordsPerPage}
              paginationRowsPerPageOptions={[15, 20, 25, 30]}
              paginationTotalRows={totalHistory}
              onChangeRowsPerPage={newRowsPerPage => {
                setRecordsPerPage(newRowsPerPage);
                setCurrentPage_SpostHistory(1);
              }}
              onChangePage={page => setCurrentPage_SpostHistory(page)}
              noDataComponent={
                <div className="color-white text-center py-2">
                  <img src={notFound} alt="pair-notfound" style={{ width: 60, height: 60 }} /><br />
                  No Active Orders Found!
                </div>
              }
            />
          </div>
        </div>
        <TradeHistoryDetail
          traHisDetails={traHisDetails}
          decimalValue={decimalValue}
        />
      </div>
    </div>
  );
}
export default SpotTradeHistory;